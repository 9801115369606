const NAMESPACES = [
  'home',
  'menu',
  'footer',
  'projetPedagogique',
  'AttestationEtRembourdement',
  'equipe',
  'cycleHiver20192020',
  'cyclePrintemps2020',
  'stagesDeTennis20192020',
]

module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: NAMESPACES,
    path: {
      fr: '/',
      en: '/en/',
    },
  },
  /************************************************ 
    Pages
  *************************************************/
  ComponentAdhesion: {
    id: 'ComponentAdhesion',
    namespace: NAMESPACES,
    path: {
      fr: '/adhesion-online',
      en: '/en/adhesion-online',
    },
  },
  ComponentInscription: {
    id: 'ComponentInscription',
    namespace: NAMESPACES,
    path: {
      fr: '/inscription-online',
      en: '/en/inscription-online',
    },
  },
  ComponentProjetPedagogique: {
    id: 'ComponentProjetPedagogique',
    namespace: NAMESPACES,
    path: {
      fr: '/projet-pedagogique',
      en: '/en/educational-programme',
    },
  },ComponentAttestationEtRembourdement: {
    id: 'ComponentAttestationEtRembourdement',
    namespace: NAMESPACES,
    path: {
      fr: '/attestation-remboursement',
      en: '/en/certificate-reimbursement',
    },
  },
  ComponentProfesseursDeTennis: {
    id: 'ComponentProfesseursDeTennis',
    namespace: NAMESPACES,
    path: {
      fr: '/professeurs-de-tennis',
      en: '/en/tennis-instructors',
    },
  },
  ComponentCyclesEtStagesCycleHiver20192020: {
    id: 'ComponentCyclesEtStagesCycleHiver20192020',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-hiver-2019-2020',
      en: '/en/winter-cycle-2019-2020',
    },
  },
  ComponentCyclesEtStagesCycleHiver20202021: {
    id: 'ComponentCyclesEtStagesCycleHiver20202021',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-hiver-2020-2021',
      en: '/en/winter-cycle-2020-2021',
    },
  },
  ComponentCyclesEtStagesCycleHiver20212022: {
    id: 'ComponentCyclesEtStagesCycleHiver20212022',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-hiver-2021-2022',
      en: '/en/winter-cycle-2021-2022',
    },
  },
  ComponentCyclesEtStagesCycleHiver20222023: {
    id: 'ComponentCyclesEtStagesCycleHiver20222023',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-hiver-2022-2023',
      en: '/en/winter-cycle-2022-2023',
    },
  },
  ComponentCyclesEtStagesCycleHiver20232024: {
    id: 'ComponentCyclesEtStagesCycleHiver20232024',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-hiver-2023-2024',
      en: '/en/winter-cycle-2023-2024',
    },
  },
  ComponentCyclesEtStagesCyclePrintemps2020: {
    id: 'ComponentCyclesEtStagesCyclePrintemps2020',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-printemps-2020',
      en: '/en/spring-cycle-2020',
    },
  },
  ComponentCyclesEtStagesCyclePrintemps2021: {
    id: 'ComponentCyclesEtStagesCyclePrintemps2021',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-printemps-2021',
      en: '/en/spring-cycle-2021',
    },
  },
  ComponentCyclesEtStagesCyclePrintemps2022: {
    id: 'ComponentCyclesEtStagesCyclePrintemps2022',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-printemps-2022',
      en: '/en/spring-cycle-2022',
    },
  },
  ComponentCyclesEtStagesCyclePrintemps2023: {
    id: 'ComponentCyclesEtStagesCyclePrintemps2023',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-printemps-2023',
      en: '/en/spring-cycle-2023',
    },
  },
  ComponentCyclesEtStagesCyclePrintemps2024: {
    id: 'ComponentCyclesEtStagesCyclePrintemps2024',
    namespace: NAMESPACES,
    path: {
      fr: '/cycle-printemps-2024',
      en: '/en/spring-cycle-2024',
    },
  },
  ComponentCyclesEtStagesStages: {
    id: 'ComponentCyclesEtStagesStages',
    namespace: NAMESPACES,
    path: {
      fr: '/stages-de-tennis',
      en: '/en/tennis-courses',
    },
  },
}
